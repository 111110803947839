import { ParsedUrlQueryInput } from 'querystring';
import { UrlObject } from 'url';
import APP_ROUTES from 'src/common/appRoutes';
import { CARD } from 'src/common/constants';
import { NotificationAction } from 'src/interface/INotificationState';

const getLinkProfile = (id: number, myID: number): string | UrlObject => {
  return id === myID ? APP_ROUTES.PROFILE : { pathname: APP_ROUTES.PROFILE_DETAIL, query: { id } };
};

export type LinkActivityProps = {
  type: string;
  query?: ParsedUrlQueryInput;
  isDetail?: boolean;
};

const getLinkActivity = ({ type, query, isDetail }: LinkActivityProps): UrlObject => {
  switch (type) {
    case CARD.STATUS:
      return {
        pathname: isDetail ? APP_ROUTES.ACTIVITY_STATUS_DETAIL : APP_ROUTES.ACTIVITY_STATUS,
        query
      };
    case CARD.NEWS:
      return {
        pathname: isDetail ? APP_ROUTES.ACTIVITY_NEWS_DETAIL : APP_ROUTES.ACTIVITY_NEWS,
        query
      };
    case CARD.ANNOUNCEMENT:
      return {
        pathname: isDetail
          ? APP_ROUTES.ACTIVITY_ANNOUNCEMENTS_DETAIL
          : APP_ROUTES.ACTIVITY_ANNOUNCEMENTS,
        query
      };
    default:
      return {
        pathname: APP_ROUTES.ACTIVITY
      };
  }
};

export type NotificationLinkProps = {
  type: string;
  id: number;
  query?: ParsedUrlQueryInput;
};

const getNotificationLink = ({ type, id, query }: NotificationLinkProps): UrlObject => {
  const queryWithID = { ...query, id };
  switch (type) {
    case NotificationAction.OPEN_ANNOUNCEMENT:
      return {
        pathname: APP_ROUTES.ACTIVITY_ANNOUNCEMENTS_DETAIL,
        query: queryWithID
      };
    case NotificationAction.OPEN_NEWS:
      return {
        pathname: APP_ROUTES.ACTIVITY_NEWS_DETAIL,
        query: queryWithID
      };
    case NotificationAction.OPEN_STATUS:
      return {
        pathname: APP_ROUTES.ACTIVITY_STATUS_DETAIL,
        query: queryWithID
      };
    case NotificationAction.OPEN_COMMENT_BY_ANNOUNCEMENT:
      return {
        pathname: APP_ROUTES.ACTIVITY_ANNOUNCEMENTS_DETAIL,
        query: queryWithID
      };
    case NotificationAction.OPEN_COMMENT_BY_NEWS:
      return {
        pathname: APP_ROUTES.ACTIVITY_NEWS_DETAIL,
        query: queryWithID
      };
    case NotificationAction.OPEN_COMMENT_BY_STATUS:
      return {
        pathname: APP_ROUTES.ACTIVITY_STATUS_DETAIL,
        query: queryWithID
      };
    case NotificationAction.REMIND_HEATMAP:
      return {
        pathname: APP_ROUTES.HEATMAP,
        query
      };
    case NotificationAction.OPEN_PROFILE:
      return {
        pathname: APP_ROUTES.PROFILE,
        query
      };
    default:
      return {
        pathname: APP_ROUTES.ACTIVITY
      };
  }
};

export { getLinkProfile, getLinkActivity, getNotificationLink };
