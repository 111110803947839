export interface InotificationState {
  notifications: InotificationItem[];
  hasLoadmore: boolean;
  params: {
    page: number;
    limit: number;
  };
  isPending: boolean;
  isLoadingMore: boolean;
  unread_num: number;
  count: number;
}

export interface InotificationItem {
  id: number;
  kind: number;
  action_click: string;
  object_id: number;
  title: string;
  content: string;
  is_read: boolean;
  created_at: string;
  type: string;
  avatar_url: string;
  tags?: ItagNotificationItem[];
}

interface ItagNotificationItem {
  id: number;
  name: string;
  taggings_count: number;
  kind: number;
  color: string;
}

export enum NotificationAction {
  OPEN_ANNOUNCEMENT = 'open_announcement',
  OPEN_NEWS = 'open_news',
  OPEN_STATUS = 'open_status',
  OPEN_COMMENT_BY_ANNOUNCEMENT = 'open_comment_by_announcement',
  OPEN_COMMENT_BY_NEWS = 'open_comment_by_news',
  OPEN_COMMENT_BY_STATUS = 'open_comment_by_status',
  REMIND_HEATMAP = 'remind_heatmap',
  OPEN_PROFILE = 'open_profile',
  NO_OPEN = 'no_open'
}
